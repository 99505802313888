import React from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useMessage } from "./useMessage";

export const basesUrl = window.location.host.includes("localhost")
  ? "https://edugate.edu.gov.kg/api/site"
  : "/api/site";
export const mainUrl = "https://edugate.edu.gov.kg";

export const useHttp = () => {
  const message = useMessage();
  const [loader, setLoader] = React.useState(false);

  const { t } = useTranslation();

  const request = React.useCallback(
    async (url, method = "get", body = {}, header = null) => {
      try {
        setLoader((pre) => (pre = true));

        const res = await axios[method](basesUrl + url, body, header);

        setLoader((pre) => (pre = false));
        return res.data;
      } catch (e) {
        setLoader(false);
        message(e.response?.data?.message || t("somethingWrong"), "error");
      }
    },
    []
  );

  return { request, loader };
};
