import React, { useState } from "react";
import { useHttp } from "../../services/hooks/useHttp";
import { AppContext } from "../../services/hooks/Contex";

import Loader from "../../components/Loader/Loader";
import "./register_page.scss";
import RegistrationComponent from "../../components/RegistrationSteps/RegistrationComponent/RegistrationComponent";
import SendKeyComponent from "../../components/RegistrationSteps/SendKeyComponent/SendKeyComponent";
import ConfirmKeyComponent from "../../components/RegistrationSteps/ConfirmKeyComponent/ConfirmKeyComponent";
import ProgressComponent from "../../components/RegistrationSteps/ProgressComponent/ProgressComponent";
import { t } from "i18next";
import IsNotActive from "../../containers/Registration/IsNotActive";
import { Services } from "../../services/services";

const RegisterPage = React.memo(() => {
  const { setActiveLinkNavbar } = React.useContext(AppContext);
  const { request, loader } = useHttp();
  const lang = localStorage.getItem("lang");

  const [progress, setProgress] = useState(1);
  const [id_unversity_user, setIdUniverUser] = useState();
  const [role, setRole] = useState();
  const [kol, setKol] = useState();
  const [registrationIsActive, setRegistrationIsActive] = useState(0);

  const selectProgress = (value) => {
    setProgress(value);
  };

  React.useEffect(() => {
    setActiveLinkNavbar(5);
    tourCheck();
  }, [lang]);

  const tourCheck = () => {
    Services.checkTour().then(
      (response) => {
        if (response?.data?.data) {
          setRegistrationIsActive(response?.data?.data?.[0]?.status);
        }
      },
      (error) => {
        setRegistrationIsActive(0);
      }
    );
  };

  return (
    <>
      {loader && (
        <div className="loader_register">
          <div style={{ zIndex: 5 }}>
            <Loader />
          </div>
        </div>
      )}
      <div className="container_max_width_register">
        <div className="container_register">
          {/* <ProgressComponent
                        onclick={selectProgress}
                        value={progress}
                        id_unversity_user={id_unversity_user}
                        role={role}
                        kol={kol}
                    /> */}

          {/* {progress == 1 ?
                        <SendKeyComponent
                            selectProgress={selectProgress}
                        /> :
                        progress == 2 ?
                            < ConfirmKeyComponent
                                selectProgress={selectProgress}
                            /> :
                            progress == 3 ? */}
          {registrationIsActive ? (
            <RegistrationComponent request={request} />
          ) : (
            <IsNotActive />
          )}
          {/* : null} */}
        </div>
      </div>
    </>
  );
});

export default RegisterPage;
