import axios from "axios";

const HOST = "https://edugate.edu.gov.kg";
// const HOST = "http://localhost:3035";
export const dopUrl = "";
export const baseURL = `${HOST}`;
export const apiURL = `/api/v1/`;

const http = axios.create({
  baseURL: `${baseURL}${apiURL}`,
  headers: {
    "Content-type": "application/json",
    Cookie: "ORTPORTAL=0ea7ee82-cb49-4c3c-827a-a036260b674e;",
    "Access-Control-Allow-Origin":
      "http://localhost:3000, http://localhost:3009, https://edugate.edu.gov.kg ,",
    SameSite: "None",
  },
});

const checkTour = async () => {
  const res = await http.post("tour/tour/check_tour", {});
  return res;
};

const planReport = async ({ id_univ, id_lan, id_year, id_ws }) => {
  const res = await http.post("admin/reports/plan_report", {
    id_univ,
    id_lan,
    id_year,
    id_ws,
  });
  return res;
};

export const Services = {
  checkTour,
  planReport,
};
