import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

function DesktopAboutTable({ aboutUniversities, params, t }) {
  return (
    <>
      {aboutUniversities.keys?.length ? (
        aboutUniversities.keys?.map((key, index) => (
          <div key={index} className="main_container_table_about_universities">
            <div
              style={{
                width: "100%",
                backgroundColor: "white",
                padding: "30px",
                borderRadius: "20px",
                margin: "70px 20px 0 20px",
                overflowX: "auto",
              }}
            >
              <h1>
                {index + 1}. {aboutUniversities.list[key][0]?.faculty}
              </h1>
              <table
                style={{
                  textAlign: "center",
                  borderCollapse: "collapse",
                  marginTop: "20px",
                  width: "100%",
                }}
              >
                <tbody className="table_about">
                  <tr>
                    <td className="table_head">
                      {t("aboutUniversities.cipher")}
                    </td>
                    <td className="table_head" style={{ minWidth: "320px" }}>
                      {t("aboutUniversities.speciality")}
                    </td>
                    <td className="table_head">
                      {t("aboutUniversities.typeBuy")}
                    </td>
                    <td className="table_head">{t("aboutUniversities.el")}</td>
                    <td className="table_head">
                      {t("aboutUniversities.summa")}
                      <div className="td_inner_columns">
                        <p title={t("aboutUniversities.estimate_study_eaeu")}>
                          {t("aboutUniversities.eaeu")}
                        </p>
                        <p
                          title={t(
                            "aboutUniversities.estimate_study_near_abroad"
                          )}
                        >
                          {t("aboutUniversities.nac")}
                        </p>
                        <p
                          title={t(
                            "aboutUniversities.estimate_study_far_abroad"
                          )}
                        >
                          {t("aboutUniversities.fac")}
                        </p>
                      </div>
                    </td>
                    <td className="table_head">
                      {t("aboutUniversities.plan")}
                    </td>
                    <td className="table_head">
                      {t("aboutUniversities.quantity")}
                    </td>
                    <td className="table_head"></td>
                  </tr>
                  {aboutUniversities.list[key]?.map((item, index) => (
                    <tr key={index} className="table_body_tr">
                      <td
                        style={{ verticalAlign: "top" }}
                        className="table_body"
                      >
                        {item.cipher}
                      </td>
                      <td className="table_body table_direction">
                        <div>
                          <span style={{ fontSize: "16px" }}>
                            {item.direction}
                          </span>
                        </div>
                        <div className="speciality">
                          <span className="title">
                            {t("aboutUniversities.speciality2")}:{" "}
                          </span>
                          <span className="about">{item.specialty}</span>
                        </div>
                        <div className="speciality">
                          <span className="title">
                            {t("aboutUniversities.educationForm")}:{" "}
                          </span>
                          <span className="about">{item.learning}</span>
                        </div>
                        <div className="speciality">
                          <span className="title">
                            {t("aboutUniversities.formControl")}:{" "}
                          </span>
                          <span className="about">{item.control_type}</span>
                        </div>
                        <div className="speciality">
                          <span style={{ color: "#EF7F1A", fontSize: "13px" }}>
                            {item.comments}
                          </span>
                        </div>
                      </td>
                      <td className="table_body">{item.bk}</td>
                      <td className="table_body">{item.el}</td>
                      <td className="table_body">
                        <div className="td_inner_columns">
                          <p title={t("aboutUniversities.estimate_study_eaeu")}>
                            {item.smeta_education}
                          </p>
                          <p
                            title={t(
                              "aboutUniversities.estimate_study_near_abroad"
                            )}
                          >
                            {item.smeta_near_abroad}
                          </p>
                          <p
                            title={t(
                              "aboutUniversities.estimate_study_far_abroad"
                            )}
                          >
                            {item.smeta_far_abroad}
                          </p>
                        </div>
                      </td>
                      <td className="table_body">{item.plan}</td>
                      {/*<td className="table_body">{item.comments}</td>*/}
                      <td className="table_body">{item.kol}</td>
                      <td className="table_body">
                        <NavLink
                          to={`/contact/${params.id_univ}/${item.id_fac}`}
                        >
                          <button className="btn_contact">
                            {t("aboutUniversities.contact")}
                          </button>
                        </NavLink>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ))
      ) : (
        <p className="none_data_text">{t("noData")}</p>
      )}
    </>
  );
}

DesktopAboutTable.propTypes = {
  t: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  aboutUniversities: PropTypes.any.isRequired,
};

export default DesktopAboutTable;
