import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BackgroundMain } from "../../../img";
import { AppContext } from "../../../services/hooks/Contex";
import DesktopAboutTable from "../../../containers/AboutTable/DesktopAboutTable";
import MobileAboutTable from "../../../containers/AboutTable/MobileAboutTable";
import "./about_universities.scss";
import { Services } from "../../../services/services";
import { useState } from "react";

function groupBy(xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

function PlanReportPage({}) {
  const params = useParams();
  const { t, i18n } = useTranslation();
  const id_lan = i18n?.language == "ru" ? 1 : 2;
  const id_univ = parseInt(params?.id_univ);
  const id_year = parseInt(params?.id_year);
  const id_ws = parseInt(params?.id_ws);

  const [aboutUniversities, setAboutUniversities] = useState({
    keys: 0,
    list: null,
  });
  const { allUniversities } = React.useContext(AppContext);
  const [screen, setScreen] = React.useState(null);
  const lang = localStorage.getItem("lang");

  const style = {
    backgroundImage: `url('${BackgroundMain}')`,
  };

  React.useEffect(() => {
    planReport();
  }, [lang]);

  const planReport = () => {
    Services.planReport({ id_univ, id_lan, id_year, id_ws }).then(
      (res) => {
        if (res?.data?.data) {
          const data = res?.data?.data;
          const list = groupBy(data, "id_fac");
          const keys = Object.keys(list);
          setAboutUniversities({ keys, list });
        }
      },
      (error) => {
        console.log("error", error);
      }
    );
  };

  React.useEffect(() => {
    setScreen(document.body.offsetWidth);
  }, []);

  return (
    <div style={{ minHeight: "100vh" }}>
      <div style={style} className="container_main">
        <div className="container_max_width">
          <h1 className="about_main">
            {allUniversities.map((item) => {
              if (item.id_univ == params.id_univ) {
                return item.university;
              }
            })}
          </h1>
          <div className="container_about_text">
            <p>
              {allUniversities.map((item) => {
                if (item.id_univ == params.id_univ) {
                  return item.position;
                }
              })}
              :{" "}
              {allUniversities.map((item) => {
                if (item.id_univ == params.id_univ) {
                  return item.supervisor;
                }
              })}
              .
            </p>
            <p>
              {t("card.address")}:
              {allUniversities.map((item) => {
                if (item.id_univ == params.id_univ) {
                  return item.address;
                }
              })}
            </p>
            <p>
              {t("card.site")}:{" "}
              {allUniversities.map((item) => {
                if (item.id_univ == params.id_univ) {
                  return (
                    <a
                      target="_blank"
                      style={{ color: "white" }}
                      href={item.url}
                    >
                      {item.url}
                    </a>
                  );
                }
              })}
            </p>
            <p>{t("aboutUniversities.komissia")}</p>
          </div>
        </div>
      </div>
      {screen > 1000 ? (
        <DesktopAboutTable
          aboutUniversities={aboutUniversities}
          params={params}
          t={t}
        />
      ) : (
        <MobileAboutTable
          aboutUniversities={aboutUniversities}
          params={params}
          t={t}
        />
      )}
    </div>
  );
}

export default PlanReportPage;
