import React from 'react';
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Questions} from "../../components";
import {AppContext} from "../../services/hooks/Contex";
import {BackgroundMain} from "../../img";
import './questions_page.scss'

function QuestionsPage({}) {

    const {t} = useTranslation()
    const {setActiveLinkNavbar} = React.useContext(AppContext)

    const style = {
        backgroundImage: `url('${BackgroundMain}')`
    }

    React.useEffect(() => {
        setActiveLinkNavbar(4)
    }, [])

    return (
        <>
            <div style={style} className="container_questions">
                <div className="container_max_width_questions">
                    <p>
                        <span>
                            <NavLink style={{color: '#EF7F1A'}} to='/'>{t("MainPageAllUniversities.main")}</NavLink>
                        </span>/{t('questions.main.title')}
                    </p>
                    <h1 className='about_questions'>
                         {t('questions.main.title')}
                    </h1>
                </div>
            </div>
            <Questions/>
        </>
    );
}

export default QuestionsPage;