import React from "react";
import {render} from "react-dom";
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import {store} from "./redux/store";
import Loader from "./components/Loader/Loader";
import App from "./App";
import 'react-toastify/dist/ReactToastify.css';
import './styles/index.scss'
import './i18n'

render(
    <Provider store={store}>
        <BrowserRouter>
            <React.Suspense fallback={<div style={{marginTop: '50vh'}}><Loader/></div>}>
                <App/>
            </React.Suspense>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
)