import axios from "axios";
import {basesUrl} from "../../services/hooks/useHttp";
import {COUNTRY} from "../types/types";

export const countryAction = (id_lan) => async dispatch => {
    try {
        const {data} = await axios.post(basesUrl + '/country', {id_lan})
        dispatch({type: COUNTRY, payload: data})
    } catch (e) {
    }
}