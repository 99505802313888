import React from 'react';
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Input, SelectComponent} from "../../../components";
import CardAllUniversities from "../../../containers/CardAllUniversities/CardAllUniversities";
import Loader from "../../../components/Loader/Loader";
import {AppContext} from "../../../services/hooks/Contex";
import {BackgroundMain} from "../../../img";
import './main_universities.scss'

function MainUniversities() {

    const {loader, allUniversities, setActiveLinkNavbar} = React.useContext(AppContext)

    const {t} = useTranslation()

    const style = {
        backgroundImage: `url('${BackgroundMain}')`
    }

    React.useEffect(() => {
        setActiveLinkNavbar(2)
    }, [])

    return (
        <>
            <div style={style} className="container_main">
                <div className="container_max_width">
                    <p>
                        <span>
                            <NavLink style={{color: '#EF7F1A'}} to='/'>{t("MainPageAllUniversities.main")}</NavLink>
                        </span>/{t("MainPageAllUniversities.mainText")}
                    </p>
                    <h1 className='about_main'>
                        {t("MainPageAllUniversities.mainText")}
                    </h1>
                    <div className="container_form">
                        <SelectComponent/>
                        <Input/>
                    </div>
                </div>
            </div>

            <div className="container_table">
                <div style={allUniversities.length <= 3 ? {alignItems: 'start'} : {}}
                     className="container_table_max_width">
                    {
                        allUniversities.length ?
                            loader ?
                                <Loader/> :
                                allUniversities?.map((item, index) => <CardAllUniversities
                                    {...item}
                                    index={index + 1}
                                    key={index + 1}
                                />)
                            :
                            <p className="none_data_text">{t('noData')}</p>
                    }
                </div>
            </div>
        </>
    );
}

export default MainUniversities;