import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./navbar.scss";
import { mainUrl } from "../../services/hooks/useHttp";
import { setCookie } from "../../utils.js/cookie";

function Navbar({ activeLinkNavbar }) {
  const [activeMobileNavigation, setActiveNavigation] = React.useState(false);

  const { t, i18n } = useTranslation();
  const lang = localStorage.getItem("lang");

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem("lang", language);
    setCookie("lang", language);
  };

  let liClass = (isActive) => {
    if (isActive) return "navbar__link_active";
  };

  console.log("====================================");
  console.log(liClass());
  console.log("====================================");

  const windowScrollY = () => {
    window.scrollTo({
      top: 0,
      // behavior: 'smooth'
    });
  };

  const changeMobileNavigation = () => {
    windowScrollY();
    setActiveNavigation(false);
  };

  const LogoUrl =
    i18n?.language == "ru"
      ? mainUrl + "/images/logo_bk_ru.svg"
      : mainUrl + "/images/logo_bk_en.svg";

  return (
    <>
      {activeMobileNavigation && (
        <div
          onClick={setActiveNavigation.bind(null, !activeMobileNavigation)}
          className="black_navbar_fon"
        />
      )}
      <header>
        <div className="header_container">
          <div className="container_max_width">
            <div className="main_logo">
              <div>
                <img src={LogoUrl} alt="logo" />
              </div>
              <div
                className="link_language"
                onClick={changeLanguage.bind(null, "ru")}
                style={lang === "ru" ? { fontWeight: "bold" } : null}
              >
                Рус
              </div>
              <div
                className="link_language"
                onClick={changeLanguage.bind(null, "en")}
                style={lang === "en" ? { fontWeight: "bold" } : null}
              >
                En
              </div>
            </div>

            <div className="container_navigations_head">
              <nav>
                <ul>
                  <NavLink
                    className={({ isActive }) => liClass(isActive)}
                    to="/"
                  >
                    <li onClick={windowScrollY}>{t("navbar.home")}</li>
                  </NavLink>
                  <NavLink
                    className={({ isActive }) => liClass(isActive)}
                    to="/universities"
                  >
                    <li onClick={windowScrollY}>{t("navbar.universities")}</li>
                  </NavLink>
                  <NavLink
                    className={({ isActive }) => liClass(isActive)}
                    to="/opendata"
                  >
                    <li onClick={windowScrollY}>{t("navbar.openData")}</li>
                  </NavLink>
                  <NavLink
                    className={({ isActive }) => liClass(isActive)}
                    to="/questions"
                  >
                    <li onClick={windowScrollY}>{t("navbar.question")}</li>
                  </NavLink>
                  <NavLink
                    className={({ isActive }) => liClass(isActive)}
                    to="/register"
                  >
                    <li onClick={windowScrollY}>{t("navbar.register")}</li>
                  </NavLink>
                  <a href="https://edugate.edu.gov.kg/applicant/login/">
                    <li onClick={windowScrollY} className="entrance_link">
                      {t("navbar.login")}
                    </li>
                  </a>
                </ul>
              </nav>
            </div>
          </div>
          <i
            onClick={setActiveNavigation.bind(null, !activeMobileNavigation)}
            id="burger"
            className="bi bi-list"
          />
        </div>
        <div
          className={
            activeMobileNavigation
              ? "navigation_mobile active_navigation_mobile"
              : "navigation_mobile"
          }
        >
          <nav>
            <ul>
              <NavLink
                onClick={changeMobileNavigation}
                style={{ textDecoration: "none" }}
                to="/"
              >
                <div
                  className={
                    activeLinkNavbar == 1
                      ? "link_mobile_navigations active_li_navigation"
                      : "link_mobile_navigations"
                  }
                >
                  <i className="fa-solid fa-house" />
                  {t("navbar.home")}
                </div>
              </NavLink>
              <NavLink
                onClick={changeMobileNavigation}
                style={{ textDecoration: "none" }}
                to="/universities"
              >
                <div
                  className={
                    activeLinkNavbar == 2
                      ? "link_mobile_navigations active_li_navigation"
                      : "link_mobile_navigations"
                  }
                >
                  <i className="fa-solid fa-building-columns" />
                  {t("navbar.universities")}
                </div>
              </NavLink>
              <NavLink
                onClick={changeMobileNavigation}
                style={{ textDecoration: "none" }}
                to="/opendata"
              >
                <div
                  className={
                    activeLinkNavbar == 3
                      ? "link_mobile_navigations active_li_navigation"
                      : "link_mobile_navigations"
                  }
                >
                  <i className="fa-solid fa-database" />
                  {t("navbar.openData")}
                </div>
              </NavLink>
              <NavLink
                onClick={changeMobileNavigation}
                style={{ textDecoration: "none" }}
                to="/questions"
              >
                <div
                  className={
                    activeLinkNavbar == 4
                      ? "link_mobile_navigations active_li_navigation"
                      : "link_mobile_navigations"
                  }
                >
                  <i className="fa-solid fa-question" />
                  {t("navbar.question")}
                </div>
              </NavLink>
              <NavLink
                onClick={changeMobileNavigation}
                style={{ textDecoration: "none" }}
                to="/register"
              >
                <div
                  className={
                    activeLinkNavbar == 5
                      ? "link_mobile_navigations active_li_navigation"
                      : "link_mobile_navigations"
                  }
                >
                  <i className="fa-solid fa-pen-to-square" />
                  {t("navbar.register")}
                </div>
              </NavLink>
              <a href="https://edugate.edu.gov.kg/applicant/login/">
                <div className="link_mobile_navigations">
                  <i className="fa-solid fa-arrow-right-from-bracket" />
                  {t("navbar.login")}
                </div>
              </a>
            </ul>
          </nav>
        </div>
      </header>
    </>
  );
}

export default Navbar;
