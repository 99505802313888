import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import MainUniversities from "./Vuzy/MainUniversities/MainUniversities";
import AboutUniversities from "./Vuzy/AboutUniversities/AboutUniversities";
import Contact from "./Vuzy/Contact/Contact";
import QuestionsPage from "./Questions/QuestionsPage";
import OpenDataPage from "./OpenData/OpenDataPage";
// import HomePage from "./Home/HomePage";
import RegisterPage from "./Register/RegisterPage";
import { WindowError } from "../components";
import Loader from "../components/Loader/Loader";
import PlanReportPage from "./Vuzy/AboutUniversities/PlanReportPage";

const RouterPage = () => {
  const HomePage = React.lazy(() => import("./Home/HomePage"));

  return (
    <Routes>
      <Route path="/universities" element={<MainUniversities />} />
      <Route path="/plan/:id_univ" element={<AboutUniversities />} />
      <Route
        path="/plan/:id_univ/:id_year/:id_ws"
        element={<PlanReportPage />}
      />
      <Route path="/contact/:id_univ/:id_fac" element={<Contact />} />
      <Route path="/questions" element={<QuestionsPage />} />
      <Route path="/opendata" element={<OpenDataPage />} />
      <Route path="/plan" element={<Navigate replace to="/universities" />} />
      <Route
        path="/contact/:id"
        element={<Navigate replace to="/universities" />}
      />
      <Route
        path="/contact"
        element={<Navigate replace to="/universities" />}
      />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="*" element={<WindowError />} />
      <Route
        path="/"
        element={
          <React.Suspense
            fallback={
              <div
                style={{
                  height: "100vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loader />
              </div>
            }
          >
            <HomePage />
          </React.Suspense>
        }
      />
    </Routes>
  );
};

export default RouterPage;
