import React from 'react';
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {BackgroundMain} from "../../img";
import {AppContext} from "../../services/hooks/Contex";
import {defaultYear} from "../../services/data/date";
import './open_data_page.scss'

function OpenDataPage({}) {
    const {t} = useTranslation()
    const {setActiveLinkNavbar} = React.useContext(AppContext)

    const style = {
        backgroundImage: `url('${BackgroundMain}')`
    }

    React.useEffect(() => {
        setActiveLinkNavbar(3)
        cardAnimation()
    }, [])

    function cardAnimation() {
        const cards = document.querySelectorAll('.card_open_data')

        function OnEntry(entry) {
            entry.forEach(item => {
                if (item.isIntersecting) {
                    setTimeout(() => {
                        item.target.style.opacity = '1'
                    }, 100)
                    return item.target.classList.add('card_animation_active')
                }
            })
        }

        const observer = new IntersectionObserver(OnEntry, {threshold: [0.5]})

        for (let card of cards) {
            observer.observe(card)
        }
    }

    return (
        <>
            <div style={style} className="container_open_data">
                <div className="container_max_width_open_data">
                    <p>
                        <span>
                        <NavLink style={{color: '#EF7F1A'}} to='/'>{t("MainPageAllUniversities.main")}</NavLink>
                        </span>/{t('navbar.openData')}
                    </p>
                    <h1 className='about_questions'>
                        {t('navbar.openData')}
                    </h1>
                </div>
            </div>

            <div style={{display: 'flex', justifyContent: 'center', minHeight: '50vh'}}>
                <div className="container_card_open_data">

                    <div className="card_open_data">
                        <div><i className="fa-solid fa-chart-line icon_head_card_open_data"/></div>
                        <div className="title_card_open_data">{t('openData.card1')}{' '}{defaultYear}</div>
                        <div className="open_open_data">{t('openData.open')}<i style={{marginLeft: '5px'}} className="fa-solid fa-arrow-right"/></div>
                    </div>

                    <div className="card_open_data">
                        <div><i className="fa-solid fa-clipboard-check icon_head_card_open_data"/></div>
                        <div className="title_card_open_data">{t('openData.card2')}{' '}{defaultYear}</div>
                        <div className="open_open_data">{t('openData.open')}<i style={{marginLeft: '5px'}} className="fa-solid fa-arrow-right"/></div>
                    </div>

                    <div className="card_open_data">
                        <div><i className="fa-solid fa-bars icon_head_card_open_data"/></div>
                        <div className="title_card_open_data">{t('openData.card3')}</div>
                        <div className="open_open_data">{t('openData.open')}<i style={{marginLeft: '5px'}} className="fa-solid fa-arrow-right"/></div>
                    </div>

                    <div className="card_open_data">
                        <div><i className="fa-solid fa-file-contract icon_head_card_open_data"/></div>
                        <div className="title_card_open_data">{t('openData.card4')}</div>
                        <div className="open_open_data">{t('openData.open')}<i style={{marginLeft: '5px'}} className="fa-solid fa-arrow-right"/></div>
                    </div>

                    <div className="card_open_data">
                        <div><i className="fa-solid fa-file-medical icon_head_card_open_data"/></div>
                        <div className="title_card_open_data">{t('openData.card5')}</div>
                        <div className="open_open_data">{t('openData.open')}<i style={{marginLeft: '5px'}} className="fa-solid fa-arrow-right"/></div>
                    </div>

                    <div className="card_open_data">
                        <div><i className="fa-regular fa-star icon_head_card_open_data"/></div>
                        <div className="title_card_open_data">{t('openData.card6')}</div>
                        <div className="open_open_data">{t('openData.open')}<i style={{marginLeft: '5px'}} className="fa-solid fa-arrow-right"/></div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default OpenDataPage;