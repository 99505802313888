import {
    CONTACT_UNIVERSITIES, ERROR_CONTACT_UNIVERSITIES,
    FALSE_LOADER_CONTACT_UNIVERSITIES,
    TRUE_LOADER_CONTACT_UNIVERSITIES
} from "../types/types";

const initialState = {
    contact: [],
    loader: false,
    error: false,
    defaultFaculty: null,
    url: ''
}

export const contactUniversitiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case TRUE_LOADER_CONTACT_UNIVERSITIES:
            return {...state, loader: true}
        case CONTACT_UNIVERSITIES:
            return {...state, contact: action.payload, error: false, defaultFaculty: action.payloadDefault, url: action.url}
        case FALSE_LOADER_CONTACT_UNIVERSITIES:
            return {...state, loader: false}
        case ERROR_CONTACT_UNIVERSITIES:
            return {...state, error: true}
        default:
            return state
    }
}