import React from "react";
import { terms } from "./data.ts";

import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";

export const UserAgreement = () => {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  return (
    <div className={styles.wrapper}>
      {terms.map((item) => {
        return (
          <div key={item[`title_${lang}`]}>
            <h3>{item[`title_${lang}`]}</h3>
            <div className={styles.section}>
              {item.sections.map((sec) => {
                return (
                  <div key={`${item[`title_${lang}`]} - ${sec.number}`}>
                    <p>
                      {sec.number}. {sec[`text_${lang}`]}
                    </p>
                    <ul>
                      {sec.points_ru?.length
                        ? sec[`points_${lang}`].map((p, i) => {
                            return (
                              <li
                                className={styles.ponits}
                                key={`${item[`title_${lang}`]} - ${
                                  sec.number
                                } - pont:${i}`}
                              >
                                {p}
                              </li>
                            );
                          })
                        : null}
                    </ul>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
