import React from 'react';
import {useTranslation} from "react-i18next";
import './select.scss'
import {AppContext} from "../../services/hooks/Contex";

function SelectComponent() {
    const {t} = useTranslation()

    const {setAllUniversities, defaultUniversities, regions, setUniversities, setChangeSelect, changeSelect} = React.useContext(AppContext)

    const filterRegions = (e) => {
        if (e.target.value == 0) {
            setUniversities(defaultUniversities)
            return setAllUniversities(defaultUniversities)
        }
        setChangeSelect(e.target.value)
        setAllUniversities(defaultUniversities.filter(item => item.id_region == e.target.value))
        setUniversities(defaultUniversities.filter(item => item.id_region == e.target.value))
    }

    return (
        <div className="custom_summary_select">
            <label className="select" htmlFor="slct">
                <select onChange={filterRegions} id="slct" required="required">
                    <option value="" disabled="disabled" selected="selected">{t('MainPageAllUniversities.filter')}</option>
                    <option value="0">{t("MainPageAllUniversities.allRegions")}</option>
                    {
                        regions.map(item => <option
                            key={item.id}
                            value={item.id}
                            selected={item.id == changeSelect}
                        >{item.region}</option>)
                    }
                </select>
                <svg>
                    <use href="#select-arrow-down"></use>
                </svg>
            </label>
        </div>
    );
}

export default SelectComponent