import React from 'react';
import './mobile_about_table.scss'
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

function MobileAboutTable({ aboutUniversities, params, t }) {
    return (
        <> {
            aboutUniversities.keys?.length ? aboutUniversities.keys?.map((key, index) => (
                <div key={index} className="main_container_table_about_universities_mobile">
                    <div style={{
                        // backgroundColor: 'white',
                        // padding: '20px',
                        // borderRadius: '20px',
                        margin: '40px 16px 0 16px',
                        overflowX: 'auto'
                    }}>
                        <h1>{index + 1}. {aboutUniversities.list[key][0]?.faculty}</h1>
                        {
                            aboutUniversities.list[key]?.map((item, index) => (
                                <div key={index} className="item_mobile_about">
                                    <div className="card_about_mobile">
                                        <div className="head">{t("aboutUniversities.cipher")}</div>
                                        <div className="body">{item.cipher}</div>
                                    </div>
                                    <div className="card_about_mobile">
                                        <div className="head">{t("aboutUniversities.speciality")}</div>
                                        <div className="body">{item.direction}</div>
                                    </div>
                                    <div className="card_about_mobile">
                                        <div className="head">{t("aboutUniversities.speciality2")}</div>
                                        <div className="body">{item.specialty}</div>
                                    </div>
                                    <div className="card_about_mobile">
                                        <div className="head">{t("aboutUniversities.educationForm")}</div>
                                        <div className="body">{item.learning}</div>
                                    </div>
                                    <div className="card_about_mobile">
                                        <div className="head">{t("aboutUniversities.formControl")}</div>
                                        <div className="body">{item.control_type}</div>
                                    </div>
                                    {item.comments ?
                                        <div className="card_about_mobile">
                                            <div style={{ color: '#EF7F1A', fontSize: '13px' }} className="body">{item.comments}</div>
                                        </div> : null}
                                    <div className="card_about_mobile">
                                        <div className="head">{t("aboutUniversities.typeBuy")}</div>
                                        <div className="body">{item.bk}</div>
                                    </div>
                                    <div className="card_about_mobile">
                                        <div className="head">{t("aboutUniversities.estimate_study_eaeu")}</div>
                                        <div className="body">{item.smeta_education}</div>
                                    </div>
                                    <div className="card_about_mobile">
                                        <div className="head">{t("aboutUniversities.estimate_study_near_abroad")}</div>
                                        <div className="body">{item.smeta_near_abroad}</div>
                                    </div>
                                    <div className="card_about_mobile">
                                        <div className="head">{t("aboutUniversities.estimate_study_far_abroad")}</div>
                                        <div className="body">{item.smeta_far_abroad}</div>
                                    </div>
                                    <div className="card_about_mobile">
                                        <div className="head">{t("aboutUniversities.plan")}</div>
                                        <div className="body">{item.plan}</div>
                                    </div>
                                    <div className="card_about_mobile">
                                        <div className="head">{t("aboutUniversities.quantity")}</div>
                                        <div className="body">{item.kol}</div>
                                    </div>
                                    <div className="card_about_mobile" style={{ textAlign: 'right' }}>
                                        <NavLink to={`/contact/${params.id_univ}/${item.id_fac}`}>
                                            <button className="btn_contact">{t("aboutUniversities.contact")}</button>
                                        </NavLink>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            )) : <p className="none_data_text">{t('noData')}</p>
        }
        </>
    );
}

MobileAboutTable.propTypes = {
    t: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    aboutUniversities: PropTypes.any.isRequired
}

export default MobileAboutTable;