import React from 'react';
import {Mon} from "../../img";
import {useTranslation} from "react-i18next";
import './footer.scss'

function Footer({}) {

    const {t} = useTranslation()

    return (
        <footer className="footer_main">
            <div className="container_footer">
                <div className="item_footer">
                    <img src={Mon} alt="a"/>
                    <p>
                        <a href="https://edu.gov.kg/" target="_blank">
                            {t("footer.minister_1")}
                            <br/>
                            {t("footer.minister_2")}
                        </a>
                    </p>
                </div>

            </div>
            <div className="son">
                <div>
                    <a href="https://edugate.edu.gov.kg/mon/login/">
                        {t("MON")}
                    </a>
                </div>
                <div>
                    <a href="https://edugate.edu.gov.kg/vuz/login/">
                        {t("VUZ")}
                    </a>
                </div>
            </div>
            <div>
                <div className="footer_end">
                    {t("footer.prava")}
                </div>
            </div>
        </footer>
    );
}

export default Footer;