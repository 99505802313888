import React from 'react';
import Collapsible from "react-collapsible";
import {useTranslation} from "react-i18next";
import {Pazl} from "../../img";
import './question.scss'

function Questions({}) {
    const {t} = useTranslation()
    const time = 200

    return (
        <div className="container_questions">
            <div className="content_questions">

                <div style={{width: '90%'}}>
                    <Collapsible className="questions_animations" transitionTime={time}
                                 trigger={t('questions.1.question')}>
                        <p className="title_details">{t('questions.1.title1')}</p>
                        <ul className="ul_details">
                            <li className="li_details">{t('questions.1.title2')}</li>
                            <li className="li_details">{t('questions.1.title3')}</li>
                            <li className="li_details">{t('questions.1.title4')}</li>
                        </ul>
                    </Collapsible>

                    <Collapsible className="questions_animations" transitionTime={time}
                                 trigger={t('questions.2.question')}>
                        <p className="title_details">{t('questions.2.title1')}</p>
                        <ul className="ul_details">
                            <li className="li_details">{t('questions.2.title2')}</li>
                            <li className="li_details">{t('questions.2.title3')}</li>
                            <li className="li_details">{t('questions.2.title4')}</li>
                            <li className="li_details">{t('questions.2.title5')}</li>
                            <li className="li_details">{t('questions.2.title6')}</li>
                        </ul>
                    </Collapsible>

                    <Collapsible className="questions_animations" transitionTime={time}
                                 trigger={t('questions.3.question')}>
                        <p className="title_details">{t('questions.3.title1')}</p>
                    </Collapsible>

                    <Collapsible className="questions_animations" transitionTime={time}
                                 trigger={t('questions.4.question')}>
                        <p className="title_details">{t('questions.4.title1')} <a href='https://2020.edu.gov.kg/vuz/' target='_blank'>https://2020.edu.gov.kg/vuz</a></p>
                    </Collapsible>

                    <Collapsible className="questions_animations" transitionTime={time}
                                 trigger={t('questions.5.question')}>
                        <p className="title_details">{t('questions.5.title1')}</p>
                    </Collapsible>

                    <Collapsible className="questions_animations" transitionTime={time}
                                 trigger={t('questions.6.question')}>
                        <p className="title_details">{t('questions.6.title1')}</p>
                    </Collapsible>

                    <Collapsible className="questions_animations" transitionTime={time}
                                 trigger={t('questions.7.question')}>
                        <p className="title_details">{t('questions.7.title1')} <a href="e-visa.gov.kg">e-visa.gov.kg</a></p>
                        <p className="title_details">{t('questions.7.title2')}</p>
                    </Collapsible>

                    <Collapsible className="questions_animations" transitionTime={time}
                                 trigger={t('questions.8.question')}>
                        <p className="title_details">{t('questions.8.title1')}</p>
                    </Collapsible>

                    <Collapsible className="questions_animations" transitionTime={time}
                                 trigger={t('questions.9.question')}>
                        <p className="title_details">{t('questions.9.title1')}</p>
                    </Collapsible>

                    <Collapsible className="questions_animations" transitionTime={time}
                                 trigger={t('questions.10.question')}>
                        <p className="title_details">{t('questions.10.title1')}</p>
                    </Collapsible>

                    <Collapsible className="questions_animations" transitionTime={time}
                                 trigger={t('questions.11.question')}>
                        <p className="title_details">{t('questions.11.title1')}</p>
                    </Collapsible>
                </div>
                <div className="pazl_item">
                    <img src={Pazl} alt="pazl" className="img_pazl"/>
                </div>
            </div>

        </div>
    );
}

export default Questions;