import {
    ABOUT_UNIVERSITIES,
    ERROR_ABOUT_UNIVERSITIES,
    FALSE_LOADER_ABOUT_UNIVERSITIES,
    TRUE_LOADER_ABOUT_UNIVERSITIES
} from "../types/types";

const initialState = {
    aboutUniversities: [],
    loader: false,
    error: false
}

export const aboutUniversitiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case TRUE_LOADER_ABOUT_UNIVERSITIES:
            return {...state, loader: true}
        case ABOUT_UNIVERSITIES:
            return {...state, aboutUniversities: action.payload, error: false}
        case FALSE_LOADER_ABOUT_UNIVERSITIES:
            return {...state, loader: false}
        case ERROR_ABOUT_UNIVERSITIES:
            return {...state, error: true}
        default:
            return state
    }
}