import React from 'react';
import {useTranslation} from "react-i18next";
import {AppContext} from "../../services/hooks/Contex";
import './input.scss'

function Input() {
    const [changeSearch, setChangeSearch] = React.useState('')

    const {setAllUniversities, defaultUniversities, universities, setChangeSelect, changeSelect} = React.useContext(AppContext)

    const resetAllUniversities = () => {
        setChangeSelect(0)
        setAllUniversities(pre => pre = defaultUniversities)
        setChangeSearch(pre => pre = '')
    }

    React.useEffect(() => {
        resetAllUniversities()
    }, [])

    React.useEffect(() => {
        setChangeSearch('')
    }, [changeSelect])

    const {t} = useTranslation()

    const search = (e) => {
        // setAllUniversities(pre => pre = allUniversities.filter(item => item.id_univ === e.target.value.trim()))
        setChangeSearch(pre => pre = e.target.value.trim())
        let searchName = e.target.value.trim()
        if (searchName.length) {
        if (searchName && searchName !== null && searchName !== ' ') {
            let newStr = searchName[0].toUpperCase() + searchName.slice(1)
            let newStrUp = searchName.toUpperCase()
            let newStrLow = searchName.toLowerCase()
            let newStrUpercase = searchName[0].toUpperCase() + searchName.slice(1).toLowerCase()

            const sortTeacher_List = universities && universities.length && universities.filter(item =>
                item.university.includes(newStr) + item.university.includes(newStrUp) + item.university.includes(newStrLow) + item.university.includes(newStrUpercase) + item.university.includes(searchName))

            setTimeout(() => {
                if (sortTeacher_List.length) {
                    return setAllUniversities(sortTeacher_List)
                }
                setAllUniversities([])
            }, 20);
        }
        return
        }
        return setAllUniversities(universities)
    }

    return (
        <div className="container_input_search">
            <div className="item_input">
                <input
                    type="text"
                    className="main_input"
                    value={changeSearch}
                    onChange={search}
                />
            </div>
            <div>
                <button
                    onClick={resetAllUniversities}
                    className="btn_reset"
                >{t('MainPageAllUniversities.reset')}</button>
            </div>
        </div>
    );
}

export default Input;