import React from 'react';
import {NavLink, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {BackgroundMain} from "../../../img";
import Loader from "../../../components/Loader/Loader";
import {aboutUniversitiesAction} from "../../../redux/actions/aboutUniversitiesAction";
import {AppContext} from "../../../services/hooks/Contex";
import DesktopAboutTable from "../../../containers/AboutTable/DesktopAboutTable";
import MobileAboutTable from "../../../containers/AboutTable/MobileAboutTable";
import './about_universities.scss'

function AboutUniversities({}) {
    const params = useParams()
    const dispatch = useDispatch()
    const {t} = useTranslation()

    const {aboutUniversities, loader, error} = useSelector(state => state.aboutUniversitiesReducer)
    const {allUniversities} = React.useContext(AppContext)
    const [screen, setScreen] = React.useState(null)
    const lang = localStorage.getItem('lang')

    const style = {
        backgroundImage: `url('${BackgroundMain}')`
    }

    React.useEffect(() => {
        dispatch(aboutUniversitiesAction(params.id_univ))
    }, [lang])


    React.useEffect(() => {
        setScreen(document.body.offsetWidth)
    }, [])

    return (
        <div style={{minHeight: '100vh'}}>
            <div style={style} className="container_main">
                <div className="container_max_width">
                    <p>
                        <span><NavLink style={{color: '#EF7F1A'}}
                                       to='/'>{t("MainPageAllUniversities.main")}</NavLink> / <NavLink
                            style={{color: '#EF7F1A'}}
                            to='/universities'>{t('MainPageAllUniversities.mainText')}</NavLink>  </span> / {
                        allUniversities.map(item => {
                            if (item.id_univ == params.id_univ) {
                                return item.university
                            }
                        })
                    }
                    </p>
                    <h1 className='about_main'>
                        {
                            allUniversities.map(item => {
                                if (item.id_univ == params.id_univ) {
                                    return item.university
                                }
                            })
                        }
                    </h1>
                    <div className="container_about_text">
                        <p>{
                            allUniversities.map(item => {
                                if (item.id_univ == params.id_univ) {
                                    return item.position
                                }
                            })
                        }: {
                            allUniversities.map(item => {
                                if (item.id_univ == params.id_univ) {
                                    return item.supervisor
                                }
                            })
                        }.</p>
                        <p>{t('card.address')}:{
                            allUniversities.map(item => {
                                if (item.id_univ == params.id_univ) {
                                    return item.address
                                }
                            })
                        }</p>
                        <p>{t('card.site')}: {
                            allUniversities.map(item => {
                                if (item.id_univ == params.id_univ) {
                                    return <a target="_blank" style={{color: 'white'}} href={item.url}>{item.url}</a>
                                }
                            })
                        }</p>
                        <p>{t('aboutUniversities.komissia')}</p>
                    </div>
                </div>
            </div>
            {
                loader ?
                    <Loader/> :
                    screen > 1000 ?
                        <DesktopAboutTable
                            aboutUniversities={aboutUniversities}
                            params={params}
                            t={t}
                        />
                        :
                        <MobileAboutTable
                            aboutUniversities={aboutUniversities}
                            params={params}
                            t={t}
                        />
            }
        </div>
    );
}

export default AboutUniversities;