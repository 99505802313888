import axios from "axios";
import {basesUrl} from "../../services/hooks/useHttp";
import {
    ABOUT_UNIVERSITIES,
    ERROR_ABOUT_UNIVERSITIES,
    FALSE_LOADER_ABOUT_UNIVERSITIES,
    TRUE_LOADER_ABOUT_UNIVERSITIES
} from "../types/types";

function groupBy(xs, key) {
    return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

export const aboutUniversitiesAction = (id_univ) => async dispatch => {
    try {
        const lang = localStorage.getItem('lang')
        dispatch({type: TRUE_LOADER_ABOUT_UNIVERSITIES})
        const {data} = await axios.post(basesUrl + '/about', {id_univ: id_univ, id_lan: lang === 'ru' ? 1 : 2})

        const list = groupBy(data, "id_fac")
        const keys = Object.keys(list)
        dispatch({type: ABOUT_UNIVERSITIES, payload: {list, keys}})
        dispatch({type: FALSE_LOADER_ABOUT_UNIVERSITIES})
    } catch (e) {
        dispatch({type: FALSE_LOADER_ABOUT_UNIVERSITIES})
        dispatch({type: ERROR_ABOUT_UNIVERSITIES})
    }
}