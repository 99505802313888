import {COUNTRY} from "../types/types";

const initialState = {
    country: []
}

export const countryReducer = (state = initialState, action) => {
    switch (action.type) {
        case COUNTRY:
            return {country: action.payload}
        default:
            return state
    }
}