import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import { mainUrl } from "./services/hooks/useHttp";

var userLang = navigator.language || navigator.userLanguage;

i18n
    // Подключение бэкенда i18next
    .use(Backend)
    // Автоматическое определение языка
    .use(LanguageDetector)
    // модуль инициализации
    .use(initReactI18next)

    .init({
        // Стандартный язык
        debug: true,
        fallbackLng: userLang?.includes("ru") ? "ru" : "en",
        // Распознавание и кэширование языковых кук
        detection: {
            order: ["querystring", "cookie", "localStorage"],
            cache: ["cookie", "localStorage"],
            lookupQuerystring: "lang",
            lookupCookie: "lang",
            lookupLocalStorage: "lang"
        },
        interpolation: {
            escapeValue: false
        },
        backend: {
            loadPath: window.location.host.includes('localhost') ? '/locales/{{lng}}/translation.json' : `${mainUrl}/site/locales/{{lng}}/translation.json`
        }
    })

export default i18n