import React from 'react';
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import './card_all_universities.scss'

function CardAllUniversities({index, university, url, position, supervisor, address, id_univ}) {
    const {t} = useTranslation()

    return (
        <>
            <div className="table_universities">
                <NavLink style={{textDecoration: 'none'}} to={`/plan/${id_univ}`}>
                    <h3>{index}. {university}</h3>
                </NavLink>

                <p>{t("card.address")}:</p>
                <div>{address}</div>

                <p>{position}:</p>
                <div>{supervisor}</div>

                <p>{t("card.site")}:</p>
                <div>
                    <a href={url} target="_blank">{url}</a>
                </div>
            </div>
        </>
    );
}

export default CardAllUniversities;