export const terms = [
  {
    title_ru: "Общие положения",
    title_en: "General Provisions",
    sections: [
      {
        number: 1,
        text_ru: `Настоящий Государственный портал электронных ресурсов, расположенный по адресу: https://edugate.edu.gov.kg (далее – Портал), является интернет-порталом, на котором Министерство образования и науки Кыргызской Республики (далее — Оператор) предоставляет Пользователям возможность получения государственных услуг, а также иных услуг и сервисов, представленных на Портале поставщиками услуг.`,
        text_en: `The State Portal of Electronic Resources, located at: https://edugate.edu.gov.kg (hereinafter referred to as the Portal), is an internet portal through which the Ministry of Education and Science of the Kyrgyz Republic (hereinafter referred to as the Operator) provides Users with the opportunity to obtain government services, as well as other services and resources offered on the Portal by service providers.`,
      },
      {
        number: 2,
        text_ru:
          "Настоящее пользовательское соглашение об условиях пользования Порталом (далее – Соглашение) применяется к любому использованию Портала и к любым сообщениям, электронным письмам, любой размещаемой информации с использованием Портала или схожих сервисов, обслуживаемых Порталом.",
        text_en:
          "This user agreement regarding the terms of use of the Portal (hereinafter referred to as the Agreement) applies to any use of the Portal and to any messages, emails, or any information posted using the Portal or similar services serviced by the Portal.",
      },
      {
        number: 3,
        text_ru:
          "Осуществляя пользование Порталом (загрузку любой из его страниц, написание и отправку сообщений Оператору или иным участникам Портала, прочие действия с использованием Портала) Пользователь соглашается с настоящим Соглашением и принимает его условия, становясь Пользователем Портала.",
        text_en:
          "By using the Portal (loading any of its pages, writing and sending messages to the Operator or other participants of the Portal, and other actions using the Portal), the User agrees to this Agreement and accepts its terms, becoming a User of the Portal.",
      },
      {
        number: 4,
        text_ru: "Используя Портал, Пользователь подтверждает, что:",
        text_en: "By using the Portal, the User confirms that:",
        points_ru: [
          "Является дееспособным физическим лицом, присоединившимся к настоящему Соглашению по собственному желанию, либо лицом, выступающим на законных основаниях от имени и в интересах представляемого им юридического или физического лица;",
          "Персональные данные, указанные при регистрации на Портале, соответствуют действительным;",
          "Ознакомился с условиями настоящего Соглашения в полном объеме;",
          "Принимает все условия настоящего Соглашения в полном объеме без каких-либо изъятий и ограничений со своей стороны и обязуется их соблюдать;",
          "Соглашение (в том числе любая из его частей) может быть изменено Оператором Портала. Новая редакция Соглашения вступает в силу с момента ее размещения на Портале либо доведения до сведения в иной удобной форме, если иное не предусмотрено новой редакцией Соглашения;",
          "Использование Портала будет осуществляться исключительно для целей, разрешенных действующим законодательством Кыргызской Республики и настоящим Соглашением;",
          "Не будет совершать каких-либо действий, которые вступают в противоречие или препятствуют предоставлению Портала или работе соответствующего оборудования, сетей или программного обеспечения, с помощью которых предоставляется Портал.",
        ],
        points_en: [
          "Is a capable individual who has joined this Agreement voluntarily, or a person acting legally on behalf of and in the interest of the legal or natural person they represent;",
          "The personal data provided during registration on the Portal is accurate;",
          "Has read the terms of this Agreement in full;",
          "Accepts all the terms of this Agreement in full without any exceptions or limitations on their part and undertakes to comply with them;",
          "The Agreement (including any of its parts) may be amended by the Portal Operator. The new version of the Agreement comes into effect from the moment it is posted on the Portal or brought to the attention in another convenient form, unless otherwise provided by the new version of the Agreement;",
          "The use of the Portal will be carried out exclusively for purposes permitted by the current legislation of the Kyrgyz Republic and this Agreement;",
          "Will not engage in any actions that contradict or impede the provision of the Portal or the operation of the relevant equipment, networks, or software through which the Portal is provided.",
        ],
      },
      {
        number: 5,
        text_ru:
          "Если Пользователь не принимает настоящее Соглашение, то регистрация будет прервана, а Пользователь возвращен на начальную страницу.",
        text_en:
          "If the User does not accept this Agreement, registration will be terminated, and the User will be returned to the home page.",
      },
      {
        number: 6,
        text_ru:
          "Используя Портал, Пользователь подтверждает, что у него есть необходимое оборудование, программное обеспечение и возможность пользоваться Порталом, электронная почта и доступ в Интернет. Пользователь понимает и соглашается, что Оператор Портала не несет ответственности за то, как Портал будет работать с оборудованием и программным обеспечением Пользователя.",
        text_en:
          "By using the Portal, the User confirms that they have the necessary equipment, software, and ability to use the Portal, email, and access to the Internet. The User understands and agrees that the Portal Operator is not responsible for how the Portal will operate with the User's equipment and software.",
      },
      {
        number: 7,
        text_ru:
          "Сообщая свой электронный адрес, и номер телефона, другие свои данные, Пользователь дает согласие на использование указанных средств связи, а также третьими лицами, привлекаемыми ими для целей выполнения обязательств перед Пользователем, в том числе в целях осуществления рассылок информационного и иного характера, содержащих информацию о новостях и расширении перечня услуг, размещенных на Портале, и других мероприятиях, об этапах обработки обращений Пользователя или предоставления той или иной услуги, а также иную информацию, непосредственно связанную с выполнением обязательств Оператора.",
        text_en:
          "By providing their email address, phone number, and other personal data, the User consents to the use of these means of communication, as well as by third parties engaged for the purposes of fulfilling obligations to the User, including for the purposes of sending informational and other communications containing information about news and the expansion of the list of services posted on the Portal, and other activities, about the stages of processing User requests or providing a particular service, as well as other information directly related to the fulfillment of the Operator's obligations.",
      },
    ],
  },

  {
    title_ru: "Пользование Порталом",
    title_en: "Using the Portal",
    sections: [
      {
        number: 1,
        text_ru:
          "Пользователь может пользоваться Порталом исключительно в целях, предусмотренных Соглашением.",
        text_en:
          "The User may use the Portal solely for the purposes specified in the Agreement.",
      },
      {
        number: 2,
        text_ru:
          "Пользователь не может передавать свое право пользования Порталом другим лицам без предварительного согласия Оператора Портала или наличия надлежащим образом оформленного в соответствии с законодательством согласия Пользователя на представление его интересов на Портале.",
        text_en:
          "The User may not transfer their right to use the Portal to others without the prior consent of the Portal Operator or the properly documented consent of the User in accordance with the law to represent their interests on the Portal.",
      },
      {
        number: 3,
        text_ru: "Пользователь обязуется:",
        text_en: "The User agrees to:",
        points_ru: [
          "не использовать Портал для любых коммерческих целей, не предусмотренных Соглашением;",
          "не вступать в запрещенные законодательством сделки с другими пользователями Портала;",
          "не использовать контент Портала для распространения незаконной рекламы или незаконной пропаганды и иных действий, запрещенных законодательством Кыргызской Республики.",
        ],
        points_en: [
          "not to use the Portal for any commercial purposes not specified in the Agreement;",
          "not to engage in illegal transactions with other Portal users;",
          "not to use the Portal's content for the dissemination of illegal advertising or illegal propaganda and other actions prohibited by the laws of the Kyrgyz Republic.",
        ],
      },
      {
        number: 4,
        text_ru:
          "Пользователь, отвечающий требованиям, п.1.4. и п.2.3 Соглашения, вправе заключить с Оператором Портала договор путем акцепта публичной оферты, размещенной на Портале.",
        text_en:
          "A User who meets the requirements of paragraphs 1.4 and 2.3 of the Agreement may conclude a contract with the Portal Operator by accepting the public offer posted on the Portal.",
      },
    ],
  },

  {
    title_ru: "Условия пользования Порталом",
    title_en: "Terms of Use of the Portal",
    sections: [
      {
        number: 1,
        text_ru:
          "Оператор Портала предоставляет Пользователям пользовательские функции и контент Портала, получения государственных и муниципальных услуг, а также иных услуг и сервисов, представленных на Портале, в том числе, для заключения договоров, указанных в п.2.4 Соглашения, путем акцепта оферты, размещенной на Портале.",
        text_en:
          "The Portal Operator provides Users with user functions and content of the Portal for obtaining state and municipal services, as well as other services and resources presented on the Portal, including for concluding contracts specified in paragraph 2.4 of the Agreement by accepting the offer posted on the Portal.",
      },
      {
        number: 2,
        text_ru:
          "Оператор может организовать СМС-рассылку с целью оповещения пользователей информацией об их активности на портале, получении услуг, а также их информирования о новых возможностях портала или акциях.",
        text_en:
          "The Operator may organize SMS notifications to inform users about their activities on the portal, service receipt, as well as to inform them about new opportunities or promotions.",
      },
      {
        number: 3,
        text_ru:
          "Оператор Портала имеет право использовать информацию о Пользователях, а также материалы, размещенные Пользователем на Портале, в частности, в целях оказания услуг в соответствии с законодательством Кыргызской Республики об информации персонального характера.",
        text_en:
          "The Portal Operator has the right to use information about Users, as well as materials posted by Users on the Portal, particularly for providing services in accordance with the legislation of the Kyrgyz Republic on personal data.",
      },
      {
        number: 4,
        text_ru:
          "Используя Портал и его сервисы, Пользователь соглашается со всеми условиями Соглашения и самостоятельно несет ответственность за предоставление неполной или заведомо ложной информации.",
        text_en:
          "By using the Portal and its services, the User agrees to all terms of the Agreement and is solely responsible for providing incomplete or knowingly false information.",
      },
      {
        number: 5,
        text_ru:
          "Пользователь не вправе воспроизводить, повторять и копировать, продавать и перепродавать свою учетную запись на Портале, а также использовать ее для каких-либо коммерческих целей.",
        text_en:
          "The User may not reproduce, repeat, copy, sell, or resell their account on the Portal, nor use it for any commercial purposes.",
      },
    ],
  },

  {
    title_ru: "Политика конфиденциальности",
    title_en: "Privacy Policy",
    sections: [
      {
        number: 1,
        text_ru:
          "Оператор Портала вправе сохранять данные о том, какими услугами интересуются Пользователи Портала, а также о трафике и использовании Портала. Эта информация может использоваться для обработки сообщений Пользователя и улучшения форм и сроков предоставления размещенных на Портале услуг.",
        text_en:
          "The Portal Operator has the right to retain data about which services Portal Users are interested in, as well as traffic and usage of the Portal. This information may be used for processing User messages and improving the forms and timelines for providing services listed on the Portal.",
      },
      {
        number: 2,
        text_ru:
          "Предоставляя информацию, содержащую персональные данные, Пользователь выражает свое согласие на такое использование Оператором Портала и третьими лицами, взаимодействующими с Оператором в рамках, заключенных с ним договоров и соглашений.",
        text_en:
          "By providing information containing personal data, the User expresses consent to such use by the Portal Operator and third parties interacting with the Operator within the framework of agreements concluded with them.",
      },
      {
        number: 3,
        text_ru:
          "В соответствии с настоящим соглашением Оператору и лицам, оказывающим услуги посредством Портала Пользователь дает согласие на:",
        text_en:
          "In accordance with this agreement, the User gives consent to the Operator and persons providing services through the Portal for:",
        points_ru: [
          "обработку (любая операция или набор операций, выполняемых независимо от способов держателем (обладателем) персональных данных либо по его поручению, автоматическими средствами или без таковых, в целях сбора, записи, хранения, актуализации, группировки, блокирования, стирания и разрушения персональных данных);",
          "передачу персональных данных (предоставление держателем (обладателем) персональных данных третьим лицам в соответствии с Законом Кыргызской Республики «Об информации персонального характера»).",
        ],
        points_en: [
          "processing (any operation or set of operations performed irrespective of the methods used by the holder (owner) of personal data or on their behalf, by automatic means or otherwise, for the purpose of collecting, recording, storing, updating, grouping, blocking, deleting, and destroying personal data);",
          "transfer of personal data (provision by the holder (owner) of personal data to third parties in accordance with the Law of the Kyrgyz Republic on Personal Data).",
        ],
      },
      {
        number: 4,
        text_ru:
          "Размещая (передавая) персональные, иные данные и сведения через Портал (в частности, в форме обратной связи и при регистрации на Портале), Пользователь дает согласие на то, что Оператор и/или лица, оказывающие услуги и сервисы посредством Портала, будут получать, собирать, систематизировать, накапливать, хранить, уточнять (обновлять, изменять), использовать и иным образом обрабатывать (в том числе в электронном виде) персональные данные Пользователя, а также осуществлять передачу этих персональных данных, в целях исполнения настоящего Соглашения.",
        text_en:
          "By posting (transmitting) personal and other data and information through the Portal (in particular, in the form of feedback and when registering on the Portal), the User consents to the Operator and/or persons providing services and resources through the Portal receiving, collecting, systematizing, accumulating, storing, clarifying (updating, changing), using, and otherwise processing (including electronically) the User's personal data, as well as transferring this personal data for the purpose of fulfilling this Agreement.",
      },
      {
        number: 5,
        text_ru:
          "Пользователь соглашается на возможность передачи третьим лицам его персональных и иных данных, в том числе для целей их обработки и для обеспечения функционирования сервисов Портала, исполнения настоящего пользовательского соглашения, заключенного с Оператором Портала.",
        text_en:
          "The User agrees to the possibility of transferring their personal and other data to third parties, including for processing purposes and to ensure the functioning of Portal services, as well as for the execution of this user agreement concluded with the Portal Operator.",
      },
      {
        number: 6,
        text_ru: "Пользователь ознакомлен и соглашается с тем, что:",
        text_en: "The User acknowledges and agrees that:",
        points_ru: [
          "согласие на обработку персональных данных действует с момента акцепта настоящего Пользовательского соглашения и в течение всего срока предоставления услуг и сервисов до получения отзыва Пользователем своего согласия. При этом персональные данные Пользователя по услугам и сервисам, которыми он уже воспользовался и данные об оказанной услуге и сервисе, хранятся в соответствии с законодательством Кыргызской Республики;",
          "согласие на обработку персональных данных может быть отозвано на основании письменного заявления в произвольной форме;",
          "в случае отзыва согласия на обработку персональных данных обработка персональных данных Пользователя полностью или частично может быть продолжена в соответствии со статьями 5 и 15 Закона Кыргызской Республики «Об информации персонального характера».",
          "Пользователь дает согласие на то, что Оператор будет направлять в адрес Пользователя корреспонденцию на указанный им адрес электронной почты и иные средства коммуникации с Пользователем, предоставленные им, информацию о проводимых Оператором и иными лицами, размещающими услуги и сервисы на Портале, мероприятиях и другую информацию.",
        ],
        points_en: [
          "the consent to the processing of personal data is effective from the moment of acceptance of this User Agreement and for the entire period of service provision until the User withdraws their consent. In this case, the User's personal data related to services and resources they have already used, as well as data about the provided service and resource, are stored in accordance with the legislation of the Kyrgyz Republic;",
          "consent to the processing of personal data may be revoked based on a written application in any form;",
          "in case of revocation of consent to the processing of personal data, the processing of the User's personal data may continue fully or partially in accordance with articles 5 and 15 of the Law of the Kyrgyz Republic on Personal Data.",
          "The User consents that the Operator will send correspondence to the User's specified email address and other means of communication provided by the User, including information about events and other information organized by the Operator and other parties posting services and resources on the Portal.",
        ],
      },
    ],
  },

  {
    title_ru: "Ответственность",
    title_en: "Responsibility",
    sections: [
      {
        number: 1,
        text_ru:
          "Пользователь самостоятельно несет ответственность за все действия (а также их последствия) в рамках или с использованием Портала под учетной записью Пользователя, включая случаи добровольной передачи Пользователем данных для доступа к учетной записи Пользователя третьим лицам на любых условиях (в том числе по договорам или соглашениям). При этом все действия в Портале под учетной записью Пользователя считаются произведенными самим Пользователем, за исключением случаев, когда Заявитель уведомил Оператора о несанкционированном доступе к учетной записи Пользователя и/или о любом нарушении (подозрениях о нарушении) конфиденциальности своего пароля.",
        text_en:
          "The User is solely responsible for all actions (as well as their consequences) within or using the Portal under the User's account, including cases of voluntarily providing data to third parties for access to the User's account under any conditions (including under contracts or agreements). All actions within the Portal under the User's account are considered to have been performed by the User themselves, except in cases where the Applicant has notified the Operator of unauthorized access to the User's account and/or any violation (suspicions of violation) of the confidentiality of their password.",
      },
      {
        number: 2,
        text_ru:
          "За качество услуг/сервисов и содержание предоставляемых документов посредством Портала ответственность несет поставщик услуг.",
        text_en:
          "The service provider is responsible for the quality of the services/resources and the content of the documents provided through the Portal.",
      },
      {
        number: 3,
        text_ru:
          "Оператор не несет ответственности за передачу и распространение Пользователем своих персональных данных третьим лицам, которые были получены через Портал в виде результата получения электронных услуг, размещенных на Портале, в том числе регистрационных данных в Портале.",
        text_en:
          "The Operator is not responsible for the transmission and dissemination by the User of their personal data to third parties, which were obtained through the Portal as a result of obtaining electronic services posted on the Portal, including registration data in the Portal.",
      },
      {
        number: 4,
        text_ru:
          "В момент работы на Портале Пользователю необходимо самостоятельно обеспечить бесперебойную интернет-связь. За сбои, возникшие со стороны Пользователя, Оператор ответственности не несет.",
        text_en:
          "During the operation on the Portal, the User must independently ensure uninterrupted internet connectivity. The Operator is not responsible for failures occurring on the part of the User.",
      },
      {
        number: 5,
        text_ru:
          "Оператор не несет ответственности за любые виды убытков, произошедшие при работе на Портале вследствие нарушения пункта 3.5. настоящего Пользовательского соглашения, а также за события, вызванные предоставлением неполной или заведомо ложной информации Пользователем.",
        text_en:
          "The Operator is not liable for any losses incurred while using the Portal due to violations of clause 3.5 of this User Agreement, as well as for events caused by the provision of incomplete or knowingly false information by the User.",
      },
      {
        number: 6,
        text_ru:
          "Пользователь несет ответственность за умышленное использование вредоносного программного обеспечения, применение которого повлекло сбои в работе Портала и нарушения безопасности информационных систем портала, в том числе за несанкционированный сбор и обработку персональных данных третьих лиц, а также за использование Портала под учетной записью третьих лиц.",
        text_en:
          "The User is responsible for the intentional use of malicious software, the use of which caused failures in the operation of the Portal and breaches of the security of the Portal's information systems, including unauthorized collection and processing of third-party personal data, as well as using the Portal under the account of third parties.",
      },
    ],
  },

  {
    title_ru: "Прекращение Соглашения",
    title_en: "Termination of the Agreement",
    sections: [
      {
        number: 1,
        text_ru:
          "Прекращение действия Соглашения не влияет на права, полученные сторонами в период действия Соглашения.",
        text_en:
          "The termination of the Agreement does not affect the rights acquired by the parties during the term of the Agreement.",
      },
      {
        number: 2,
        text_ru:
          "Оператор Портала или исполнитель услуги не будет нести ответственность в случае невозможности выполнить обязанности по Соглашению, если такая невозможность наступила в результате непредвиденных обстоятельств, находящихся вне разумного контроля сторон, включая в том числе неполадки в работе сети Интернет, средств связи, пожары, боевые действия, наводнения или иные стихийные бедствия и другие форс-мажорные обстоятельства.",
        text_en:
          "The Portal Operator or service provider shall not be liable in the event of inability to fulfill obligations under the Agreement if such inability arises from unforeseen circumstances beyond the reasonable control of the parties, including, but not limited to, Internet network failures, communication failures, fires, military actions, floods, or other natural disasters and other force majeure circumstances.",
      },
    ],
  },

  {
    title_ru: "Заключительные положения",
    title_en: "Final Provisions",
    sections: [
      {
        number: 1,
        text_ru:
          "В случае, если любые положения настоящего Соглашения окажутся не подлежащими применению, такие положения будут применяться в соответствии с законодательством Кыргызской Республики.",
        text_en:
          "If any provisions of this Agreement are found to be inapplicable, such provisions shall be applied in accordance with the legislation of the Kyrgyz Republic.",
      },
      {
        number: 2,
        text_ru:
          "Настоящее Соглашение регулируется законодательством Кыргызской Республики. Вопросы, не урегулированные в настоящем Соглашении, регулируются законодательством Кыргызской Республики.",
        text_en:
          "This Agreement is governed by the legislation of the Kyrgyz Republic. Issues not addressed in this Agreement are regulated by the legislation of the Kyrgyz Republic.",
      },
      {
        number: 3,
        text_ru:
          "В случае возникновения споров или разногласий, связанных с соблюдением настоящего Соглашения, Пользователь и Оператор Портала приложат все усилия, чтобы решить их с помощью переговоров. Споры, которые не были разрешены в ходе переговоров, должны разрешаться в соответствии с процедурами, установленными действующим законодательством.",
        text_en:
          "In case of disputes or disagreements related to compliance with this Agreement, the User and the Portal Operator will make every effort to resolve them through negotiations. Disputes that have not been resolved through negotiations shall be resolved in accordance with the procedures established by applicable law.",
      },
    ],
  },
];
