import React from 'react';
import {sad} from "../../img";
import {useTranslation} from "react-i18next";
import './window_error.scss'

const WindowError = ({}) => {
    const {t} = useTranslation()

    return (
        <div className="error_container">
            <div>
                <div><img src={sad} alt=""/></div>
                <div className="_404">404</div>
                <div className="title">{t('404')}</div>
            </div>
        </div>
    );
}

export default WindowError;