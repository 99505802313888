import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";
import { contactUniversitiesAction } from "../../../redux/actions/contactUniversitiesAction";
import { AppContext } from "../../../services/hooks/Contex";
import { BackgroundMain } from "../../../img";
import Loader from "../../../components/Loader/Loader";
import './contact.scss'

function Contact() {
    const dispatch = useDispatch()
    const params = useParams()
    const { t } = useTranslation()
    const lang = localStorage.getItem('lang')
    const { contact, loader, error, defaultFaculty, url } = useSelector(state => state.contactUniversitiesReducer)
    const { allUniversities } = React.useContext(AppContext)

    React.useEffect(() => {
        dispatch(contactUniversitiesAction(params.id_fac))
    }, [lang])

    const style = {
        backgroundImage: `url('${BackgroundMain}')`
    }

    return (
        <>
            <div style={style} className="container_contact">
                <div className="container_max_width">
                    <p>
                        <span>
                            <NavLink style={{ color: '#EF7F1A' }}
                                to='/'>{t("MainPageAllUniversities.main")}</NavLink>/<NavLink
                                    style={{ color: '#EF7F1A' }}
                                    to='/universities'>{t("MainPageAllUniversities.mainText")}</NavLink>/{
                                allUniversities.map((item, index) => {
                                    if (item?.id_univ == params.id_univ) {
                                        return <NavLink key={index} style={{ color: '#EF7F1A' }}
                                            to={`/about/${params.id_univ}`}>{item.university}</NavLink>
                                    }
                                })
                            }
                        </span> /
                        {t("contactMainText")}
                    </p>
                    <h1 className='about_main'>
                        {t("contactMainText")}
                    </h1>
                </div>
            </div>
            {
                loader ?
                    <div style={{ minHeight: '50vh' }}>
                        <Loader />
                    </div> :
                    <div style={{ minHeight: '60vh' }}>
                        <div className="card_contact_universities">
                            <div className="card">
                                <div style={{ backgroundColor: 'white', borderRadius: '12px', padding: '20px' }}>
                                    <h3>{
                                        allUniversities.map((item, index) => {
                                            if (item?.id_univ == params.id_univ) {
                                                return <span key={index}>{item.university}</span>
                                            }
                                        })
                                    }
                                    </h3>
                                    <p>{defaultFaculty}</p>
                                    {
                                        url ?
                                            <div style={{ marginTop: '20px' }}>
                                                <a target="_blank" href={url}>
                                                    <i
                                                        style={{ color: '#83bbe9', marginRight: '5px' }}
                                                        className="fa-solid fa-earth-asia" />
                                                    {url}
                                                </a>
                                            </div> : null
                                    }

                                    {
                                        contact.length ?
                                            contact.map((item, index) => (
                                                <div key={index} className="info_contact">
                                                    <div className="fio_contact">{item.fio}</div>
                                                    <div className="link_contact">
                                                        {
                                                            item.mobile.length ?
                                                                <div>
                                                                    <a href={`tel:${item.mobile}`} target="_blank">
                                                                        <i style={{ color: '#4d75ad' }}
                                                                            className="fa-solid fa-phone" />
                                                                        {item.mobile}
                                                                    </a>
                                                                </div> : null
                                                        }
                                                        {
                                                            item.telegram.length ?
                                                                <div>
                                                                    <a href={item.telegram} target="_blank">
                                                                        <i style={{ color: '#20a0e1' }}
                                                                            className="fa-brands fa-telegram" />
                                                                        {item.telegram}
                                                                    </a>
                                                                </div> : null
                                                        }
                                                        {
                                                            item.whatsapp.length ?
                                                                <div>
                                                                    <a href={`https://wa.me/${item.whatsapp}`} target="_blank">
                                                                        <i style={{ color: '#35ee59' }}
                                                                            className="fa-brands fa-whatsapp-square" />
                                                                        {item.whatsapp}
                                                                    </a>
                                                                </div> : null
                                                        }
                                                        {
                                                            item.email ?
                                                                <div>
                                                                    <a href={`mailto:${item.email}`} target="_blank">
                                                                        <i
                                                                            style={{ color: '#183153' }}
                                                                            className="fa-solid fa-at" />
                                                                        {item.email}
                                                                    </a>
                                                                </div> : null
                                                        }
                                                        {
                                                            item.facebook ?
                                                                <div>
                                                                    <a href={item.facebook}>
                                                                        <i style={{ color: '#0676e8' }} className="fa-brands fa-facebook-square" />
                                                                        {item.facebook}
                                                                    </a>
                                                                </div> : null
                                                        }
                                                        {
                                                            item.instagram ?
                                                                <div>
                                                                    <a href={item.instagram}>
                                                                        <i style={{ color: 'black' }} className="fa-brands fa-instagram" />
                                                                        {item.instagram}
                                                                    </a>
                                                                </div> : null
                                                        }
                                                        {
                                                            item.document ?
                                                                <div>
                                                                    <i style={{ color: '#d93025' }} className="fa-solid fa-file-pdf" />
                                                                    <a href={`/docs/${item.document}`}
                                                                        target="_blank">{t('doc')}
                                                                    </a>
                                                                </div> : null
                                                        }
                                                    </div>
                                                </div>
                                            ))
                                            :
                                            t('noData')
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    );
}

export default Contact;