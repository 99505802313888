import {
    CONTACT_UNIVERSITIES, ERROR_CONTACT_UNIVERSITIES,
    FALSE_LOADER_CONTACT_UNIVERSITIES,
    TRUE_LOADER_CONTACT_UNIVERSITIES
} from "../types/types";
import axios from "axios";
import {basesUrl} from "../../services/hooks/useHttp";


export const contactUniversitiesAction = (id_fac) => async dispatch => {
    try {
        const lang = localStorage.getItem('lang')
        dispatch({type: TRUE_LOADER_CONTACT_UNIVERSITIES})
        const {data} = await axios.post(basesUrl + '/contact', {id_fac, id_lan: lang === 'ru' ? 1 : 2})
        console.log(data, 'data')

        dispatch({type: CONTACT_UNIVERSITIES, payload: data, payloadDefault: data[0]?.faculty, url: data[0]?.faculty_url})
        dispatch({type: FALSE_LOADER_CONTACT_UNIVERSITIES})
    } catch (e) {
        dispatch({type: FALSE_LOADER_CONTACT_UNIVERSITIES})
        dispatch({type: ERROR_CONTACT_UNIVERSITIES})
    }
}