export const ABOUT_UNIVERSITIES = 'ABOUT_UNIVERSITIES'
export const TRUE_LOADER_ABOUT_UNIVERSITIES = 'TRUE_LOADER_ABOUT_UNIVERSITIES'
export const FALSE_LOADER_ABOUT_UNIVERSITIES = 'FALSE_LOADER_ABOUT_UNIVERSITIES'
export const ERROR_ABOUT_UNIVERSITIES = 'ERROR_ABOUT_UNIVERSITIES'

export const CONTACT_UNIVERSITIES = 'CONTACT_UNIVERSITIES'
export const TRUE_LOADER_CONTACT_UNIVERSITIES = 'TRUE_LOADER_ABOUT_UNIVERSITIES'
export const FALSE_LOADER_CONTACT_UNIVERSITIES = 'FALSE_LOADER_ABOUT_UNIVERSITIES'
export const ERROR_CONTACT_UNIVERSITIES = 'ERROR_CONTACT_UNIVERSITIES'

export const COUNTRY = 'COUNTRY'